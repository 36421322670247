<template>
  <div id="authentication">
    <vue-headful :title="title + ($appName ? ' | ' + $appName : '')" />
    <b-container>
      <b-row>
        <b-col id="action-area" md="6" offset-md="4">
          <h1 class="text-center">User Verification</h1>
          <div v-bind:class="{ cognitoError: cognitoResponse }" class="text-center">
            {{ cognitoResponse }}
          </div>

          <p class="text-center">{{ message }}</p>

          <div v-if="cognitoResponse == null">
            <Busy primary />
          </div>

          <div v-else>
            <p class="text-center" v-if="showSignInLink">
              <router-link
                :to="{
                  name: 'signin',
                  params: { emailprop: this.username }
                }"
                >Sign In</router-link
              >
            </p>
            <p class="text-center" v-if="showChangePasswordLink">
              <router-link
                :to="{
                  name: 'request-password-reset'
                }"
                >Change Password</router-link
              >
            </p>
            <p class="text-center" v-if="showResendVerificationLink">
              <router-link :to="{ name: 'resend-verification' }"
                >Resend Verification Email</router-link
              >
            </p>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<style lang="scss">
@import "@/styles/authentication.scss";
</style>

<script>
import CognitoAuth from "@/cognito/cognitoauth";
import router from "@/router";
import Busy from "@/components/Busy";
import Console from "@/console";

export default {
  name: "verify",
  components: {
    Busy
  },
  mounted: function () {
    document.body.className = "authenticationBody";
    if (!this.code) {
      this.message = null;
      this.showResendVerificationLink = true;
      this.cognitoResponse = "No verification code was supplied, we cannot verify your account";
    } else if (!this.username) {
      this.message = null;
      this.showResendVerificationLink = true;
      this.cognitoResponse = "No email address was supplied, we cannot verify your account";
    } else this.confirmRegistration();
  },
  destroyed: function () {
    document.body.className = "";
  },
  data() {
    return {
      title: "Verify Account",
      message:
        "We are verifying your account, you will be redirected to the sign-in page in a moment.",
      cognitoResponse: null,
      code: this.$route.query.code,
      username: this.$route.query.username,
      showResendVerificationLink: false,
      showSignInLink: false,
      showChangePasswordLink: false
    };
  },
  methods: {
    cognitoCallback(err, result) {
      Console.log(err, result);
      if (err) {
        this.message = null;
        this.hasError = true;
        switch (err.code) {
          case "NotAuthorizedException":
            this.showSignInLink = true;
            this.showChangePasswordLink = true;
            this.cognitoResponse =
              "You have already been verified, please sign-in or reset your password.";

            break;
          case "UserNotFoundException":
            this.showResendVerificationLink = true;
            this.cognitoResponse =
              "Your verification link was invalid, please resend you verification link and try again.";
            break;
          default:
            this.showSignInLink = true;
            this.showChangePasswordLink = true;
            this.showResendVerificationLink = true;
            this.cognitoResponse = err.message;
        }
      } else {
        this.message = "You're verified! You're ready to sign-in.";
        router.push({
          name: "signin",
          params: { message: "You're verified! You're ready to sign-in.", emailprop: this.username }
        });
      }
    },
    confirmRegistration() {
      CognitoAuth.confirmRegistration(
        this.username,
        this.code,
        this.cognitoCallback,
        (this.showSignInLink = false),
        (this.showChangePasswordLink = false),
        (this.showResendVerificationLink = false)
      );
    }
  }
};
</script>